import { Trans, useTranslation } from "react-i18next";
import { EnrollmentResultContent } from "../../styles/Enrollment.styled";
import { errorMsg } from "../errorMsg";
import { Button } from "../../styles/Button.styled";
import { AgentFraudScreen } from "../AgentWebPortal/AgentFraudScreen";
import { adminPortalAgent } from "../../../config";

export const PendingVerification = ({ status, isAgent, next_step }: {
    status: string;
    isAgent: boolean;
    next_step: string;
}) => {
    const { t, i18n } = useTranslation('enrollment')

    if (status === '30.E09') {
        return (
            <>
                {isAgent && <AgentFraudScreen />}
                {
                    true ? //KEEPING BOTH THE /verify PAGES COMMON either for DEPOSIT or for AUTOPAY to be the next screen
                    <EnrollmentResultContent>
                        <div className="text-center">
                            <h1 className="mb-3">{t('We are Almost There!')}</h1>
                            <b>{t('Your application for service with CleanSky Energy has been placed on hold, pending verification of your information.')}</b> 
                            <b>{t('Your service will not start until such time as your information can be validated.')}</b> 
                            <p>
                                <div style={{textAlign: "left", fontSize: "16px", marginTop: "10px"}}>
                                    <Trans i18nKey="enrollment:fraudVerbiage2611_04"></Trans>
                                </div>
                                <div style={{textAlign: "left", marginLeft: "10px", marginTop: "10px", fontSize: "16px"}}>
                                    <ul>
                                        <li>• A driver's license or state identification certificate issued by a state or territory of the United States.</li>
                                        <li>• United States or foreign passport.</li>
                                        <li>• U.S. military identification card.</li>
                                        <li>• U.S. Department of Homeland Security identification document.</li>
                                        <li>• U.S. Citizenship and Immigration Services identification document.</li>
                                        <li>• U.S. Department of State identification document.</li>
                                    </ul>
                                </div>
                                <div style={{textAlign: "left", fontSize: "16px", marginTop: "10px"}}>
                                    <Trans i18nKey="enrollment:fraudVerbiage2611_01" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}></Trans>
                                </div>
                                <div style={{textAlign: "left", fontSize: "16px", marginTop: "10px", fontWeight: "bold"}}>
                                    <Trans i18nKey="enrollment:fraudVerbiage2611_02"></Trans>
                                </div>
                                <div style={{textAlign: "left", fontSize: "16px", marginTop: "10px"}}>
                                    <Trans i18nKey="enrollment:fraudVerbiage2611_03"></Trans>
                                </div>
                                <br/>
                            </p>
                            <div className="mt-5">
                                {isAgent?
                                    <Button
                                        withArrow
                                        type="button"
                                        onClick={() => {
                                            window.location.href = adminPortalAgent
                                        }}>
                                        {t('Back to Enrollment Portal')}
                                    </Button>:
                                    <Button
                                        withArrow
                                        onClick={() => {
                                            window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                                        }}>
                                        {t('Back to Homepage')}
                                    </Button>}
                            </div>
                        </div>
                    </EnrollmentResultContent>:
                    (next_step === "autopay" ?
                    <EnrollmentResultContent>
                        <div className="text-center">
                            <h1 className="mb-3">{t('We are Almost There!')}</h1>
                            <p>
                                <b>{t('Your application for service with CleanSky Energy has been placed on hold, pending verification of your information. Your service will not start until such time as your information can be validated.')}</b>
                                <br/>
                                <br/>
                                <Trans i18nKey="enrollment:fraudWarningDescAutopay1" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}>
                                </Trans>
                            </p>
                            <p>
                                <b>{t('fraudWarningDescAutopay2')}</b>
                            </p>
                            <p>
                                {t('fraudWarningDescAutopay3')}
                            </p>
                            <div className="mt-5">
                                {isAgent?<Button
                                    withArrow
                                    onClick={() => {
                                        window.location.href = `${adminPortalAgent}`
                                    }}>
                                    {t('Back to Enrollment Portal')}
                                </Button>:
                                <Button
                                    withArrow
                                    onClick={() => {
                                        window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                                    }}>
                                    {t('Back to Homepage')}
                                </Button>}
                            </div>
                        </div>
                    </EnrollmentResultContent> :
                    <EnrollmentResultContent>
                        <div className="text-center">
                            <h1 className="mb-3">{t('We are Almost There!')}</h1>
                            <p>
                                <b>{t('Your application for service with CleanSky Energy has been placed on hold, pending verification of your information. Your service will not start until such time as your information can be validated.')}</b> 
                                <br/>
                                <br/>
                                <Trans i18nKey="enrollment:fraudWarningDescDeposit1" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}>
                                </Trans>
                            </p>
                            <p>
                                <b>{t('fraudWarningDescDeposit4')}</b> 
                                {t('fraudWarningDescDeposit2')}
                            </p>
                            <p>
                                {t('fraudWarningDescDeposit3')}
                            </p>
                            <div className="mt-5">
                                {isAgent?<Button
                                    withArrow
                                    onClick={() => {
                                        window.location.href = `${adminPortalAgent}`
                                    }}>
                                    {t('Back to Enrollment Portal')}
                                </Button>:
                                <Button
                                    withArrow
                                    onClick={() => {
                                        window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                                    }}>
                                    {t('Back to Homepage')}
                                </Button>}
                            </div>
                        </div>
                    </EnrollmentResultContent>)
                }
            </>
        )
    }

    return (
        <EnrollmentResultContent>
            <div className="text-center">
                <h1 className="mb-3">{errorMsg[status].headline}</h1>
                <p>{errorMsg[status].paragraph}</p>
                {
                    errorMsg[status].paragraph2 &&
                    <p>{errorMsg[status].paragraph2}</p>
                }
                {
                    (errorMsg[status].buttonType && errorMsg[status].buttonType === 'myaccount') ?
                    <div className="mt-5">
                        <Button
                            withArrow
                            onClick={() => {
                                window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                            }}>
                            Back to Homepage
                        </Button>
                    </div> :
                    <div className="mt-5">
                        <Button
                            onClick={() => {
                                window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                            }}>
                            Back to Homepage
                        </Button>
                    </div>
                }
            </div>
        </EnrollmentResultContent>
    )
}
