import styled from 'styled-components';
import { PlanSummaryRow } from './Enrollment.styled';

interface CompareToggleBarProps {
    show: boolean;
}

export const CompareClearBtn = styled.button`
    background-color: transparent;
    border: 0 none;
    margin-left: 8px;
    color: #999;
    font-size: 14px;
    cursor: pointer;
`

export const CompareToggleBar = styled.div<CompareToggleBarProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    bottom: ${({ show }) => show ? '0' : '-200px'};
    right: 0;
    opacity: ${({ show }) => show ? 1 : 0};
    background-color: #fff;
    padding: 16px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .3);
    border-top: 1px solid #ddd;
    transition: all .3s;
    z-index: 1;
`

export const CompareTableWrapper = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    table td {
        border-bottom: 1px solid #eee;
        padding: 12px 8px;
        text-align: center;
        font-size: 14px;
    }

    table tr:last-child td {
        border-bottom: 0 none;
    }

    table td.th {
        text-align: left;
        background-color: #fafafa;
        width: 160px;
        font-size: 13px;
        line-height: 1.2;
    }

    td i {
        color: #999;
        font-size: 14px;
        font-style: normal;
    }

    @media (max-width: 768px) {
        table td {
            padding: 8px 6px;
        }

        button {
            font-size: 12px;
            padding: 8px 6px;
            line-height: 1.2;
        }
    }
`

export const StyledPlanList = styled.div`
    display: flex;
    flex-wrap: wrap;
`

interface StyledPlanRowProps {
    active?: boolean;
    recommended?: boolean;
}

export const StyledPlanRow = styled.div<StyledPlanRowProps>`
    display: flex;
    flex-direction: column;
    background-color: ${({ active }) => active ? '#F4F8FD' : '#fff'};
    color: #000000de;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: ${({ recommended }) => recommended ?'1px 2px 9px #ACDEFF':'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px'};
    overflow: hidden;
    border-radius: 24px;
    border: ${({ active,recommended }) => active ? '2px solid rgb(37,63,102)' : (recommended?'5px solid #ACDEFF':'2px solid #E5E5E5')};
    flex: 0 0 29.33%;
    margin: ${({ recommended })  => recommended ? "0%" : "2%"};
    padding: 20px 24px;

    & > div {
        flex: 1;
    }

    @media (max-width: 1199px) {
        flex: 0 0 46%;
    }

    @media (max-width: 991px) {
        flex: 0 0 96%;
    }

    @media (max-width: 640px) {
        ${PlanSummaryRow} {
            margin: 4px 0;
        }
    }
`

export const StyledPlanRowContainer = styled.div`
    // display: flex;
    // flex-direction: column;
    // color: #000000de;
    // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // overflow: hidden;
    flex: 0 0 29.33%;
    margin: 2%;
    


    & > div {
        flex: 1;
    }

    @media (max-width: 1199px) {
        flex: 0 0 46%;
        margin-top: 40px;
    }

    @media (max-width: 991px) {
        flex: 0 0 96%;
        margin-top: 40px;

    }

    @media (max-width: 640px) {
        ${PlanSummaryRow} {
            margin: 4px 0;
        },
        margin-top: 40px;

    }
`

export const StyledAgentPlanRow = styled.div<StyledPlanRowProps>`
    display: flex;
    flex-direction: column;
    background-color: ${({ active }) => active ? '#F4F8FD' : '#fff'};
    color: #000000de;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    overflow: hidden;
    border-radius: 24px;
    border: ${({ active }) => active ? '2px solid rgb(37,63,102)' : '2px solid #E5E5E5'};
    flex: 0 0 29.33%;
    margin: 2%;
    padding: 20px 15px;

    & > div {
        flex: 1;
    }

    @media (max-width: 1199px) {
        flex: 0 0 46%;
    }

    @media (max-width: 991px) {
        flex: 0 0 96%;
    }

    @media (max-width: 640px) {
        ${PlanSummaryRow} {
            margin: 4px 0;
        }
    }
`

export const PlanUtilityRow = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;
    gap: 4px;

    @media (max-width: 640px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 8px;
    }
`

export const PlanDetailBlock = styled.div`
    margin-bottom: 16px;
    margin-top: 24px;
`