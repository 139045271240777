import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Modal, Tab, Tabs } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { PlanProps } from "../types";
import { LoadingRow } from "./loading-row";
import { PlanDesc } from "./PlanDesc";
import { Button } from "./styles/Button.styled";
import { PlanSummaryRow } from "./styles/Enrollment.styled";
import { StyledPlanRow, StyledPlanList, PlanUtilityRow, PlanDetailBlock, StyledAgentPlanRow, StyledPlanRowContainer } from "./styles/Plan.styled";
import { getCommodityIconSrc, getEcoOrGasNewPlansName } from "../common";
import { useEffect, useState } from "react";
import isAdmin from "../utils/isAdmin";
import { Collapse, CollapseProps } from "antd";
import { ArrowRightOutlined,FireOutlined } from '@ant-design/icons';
import { fetchUSNCompareDataNew, getAgentScript } from "../api/api";
import { useNavigate } from "react-router";
import { DUKE_CAMPAIGN, DUKE_CAMPAIGN_UTILITY } from "../config";

function customRound(number: number) {
    if (Number.isInteger(number)) {
        return number;
    } else {
        const integerPart = Math.floor(number);
        const decimalPart = number - integerPart;

        if (decimalPart < 0.5) {
            return integerPart;
        } else {
            return integerPart + 1;
        }
    }
}

const OhComparisonDataStatic: any = {
    'OHIOED': {
        total_average_price: "100.6",
        name: 'Ohio Edison'
    },
    'TOLED': {
        total_average_price: "98",
        name: 'Toledo Edison'
    },
    'CEIL': {
        total_average_price: "96",
        name: 'Cleveland Illuminating'
    },
    'AEP-OHPC': {
        total_average_price: "109.1",
        name: 'AEP Ohio'
    },
    'DPL': {
        total_average_price: "91.8",
        name: 'AEP Ohio'
    },
    'AEP-CSP': {
        total_average_price: "109.1",
        name: 'Columbus Southern Power'
    },
    'DUKEOH': {
        total_average_price: "96",
        name: 'Duke'
    }
}

export const PlanModal = ({
    show, handleClose, data, selectProduct, isTx, utilityList, utility, handleChangeUtility, loading, selectedPlans, handleConfirm, isDefaultGas,optionSelected,setOptionSelected,showWarning,activeKey,setActiveKey, setScriptData,setStep,zipcode
}: {
    show: boolean;
    handleClose: Function;
    data: any;
    selectProduct: Function;
    isTx: boolean;
    utilityList: any;
    utility: any;
    handleChangeUtility: Function;
    loading: boolean;
    selectedPlans: any;
    isDefaultGas: boolean;
    handleConfirm: Function;
    optionSelected: string;
    setOptionSelected:Function;
    showWarning: boolean;
    activeKey: string;
    setActiveKey: Function;
    setScriptData: Function;
    setStep:Function;
    zipcode: string;
}) => {
    const { t, i18n } = useTranslation('enrollment');
    // const [activeKey, setActiveKey] = useState(optionSelected == "Both"?"Electric":optionSelected); // Set the default active tab key
    const [isAgent,setAgent] = useState(false)
    const navigate = useNavigate();
    let sortedPlans: Array<PlanProps> = [];
    let state_code: string;
    const handleTabClick = (key:any) => {
        console.log(key)
        setActiveKey(key); // Change the active tab when a tab header is clicked
      };

      const utilityNameWrapper = (utility_name: string) => {
        let targetUtilities = ["NStar - BECO", "NStar - CAMB", "NStar - COM", "WMECO"];
    
        const cleanedUtilityName = utility_name?.replace(/\s/g, '');
        const cleanedTargetUtilities = targetUtilities?.map(utility => utility?.replace(/\s/g, '')?.toLowerCase());
        const index = cleanedTargetUtilities?.indexOf(cleanedUtilityName?.toLowerCase());
        
        if (index !== -1) {
            return `Eversource (${targetUtilities[index]?.replace("NStar", "")?.replace("-", " ").trim()})`;
        } else {
            return utility_name;
        }
    }

    const planOrder = [
        "Free Month 12",
        "September Saver 9 ",
        "Seasonal Saver 9",
        "Seasonal Saver 9 ",
        "Embrace Green 6",
        "Embrace Green 24",
        "Embrace Green 12",
        "Breathe Easy 24",
      ];

    const planOrderMa = [
        "Embrace Green 12",
        "Eco Rewards 12",
        "Eco Rewards 24",
        "Embrace Green 24",
        "Eco Rewards 6", 
        "Embrace Green 6",    
        "Ultra Clean Solar 24" 
    ]
      
      function comparePlans(plan1:any, plan2:any) {
        const order1 = (state_code=='MA'?planOrderMa:planOrder).indexOf(plan1.plan_name?.trim());
        const order2 = (state_code=='MA'?planOrderMa:planOrder).indexOf(plan2.plan_name?.trim());
        
        if (order1 !== -1 && order2 !== -1) {
          return order1 - order2;
        }
        else if (order1 !== -1) {
          return -1;
        } else if (order2 !== -1) {
          return 1;
        }
        else {
          return 0;
        }
      }

        function moveToPosition(arr: any, position: number, name: string) {
            const index = arr.indexOf(name);
            if (index !== -1) {
                const [item] = arr.splice(index, 1);
                arr.splice(position-1, 0, item);
            }
            return arr;
        }


      function comparePlans2(plan1:any, plan2:any){

        const _planOrderNew = sortedPlans?.map((item:any)=>item.plan_name);
        const planOrderNew = moveToPosition(_planOrderNew, 3, "FlexStart Intro");
        const planOrderNewDuke = moveToPosition(planOrderNew, 2, DUKE_CAMPAIGN);

        const order1 = planOrderNewDuke.indexOf(plan1.plan_name?.trim());
        const order2 = planOrderNewDuke.indexOf(plan2.plan_name?.trim());
      
        if (order1 !== -1 && order2 !== -1) {
          return order1 - order2;
        } else if (order1 !== -1) {
          return -1;
        } else if (order2 !== -1) {
          return 1;
        } else {
          return 0;
        }
      }


    const getScriptForAgent = async()=>{
        try{
            const res = await getAgentScript({
                script_id: "9",
                language: i18n.language === 'es' ? 'spanish' : 'english',
                state: isTx ? "TX" : "OH",
                enrollment_id: null,
                commodity: isTx ? "electric" : "gas",
                utility_code: selectedPlans[0]?.id_utility,
            })
            setScriptData({
                //@ts-ignore
                script: res.length>0 ? res[0]?.script_content:"",
                scriptHead: res.length>0 ? res[0]?.screen_name:"",
                dynamicData:{}
            })
        }catch(e){
            console.log(e)
        }
    }

    useEffect(()=>{
        if(!show) return;
        let params = window.location.href.split("?")[1]
        params ? navigate(`/plans?${params}`) : navigate(`/plans`)
        getScriptForAgent()
    },[show])
    
    const planBlock = () => {
        if (!utilityList || !utility) return null;
        const hasElectricPlans = data && !!data['Electric'];
        
        return (
            <div style={{display:"flex",flexDirection:"column", marginTop: "20px"}} className={`${Object.keys(utilityList).length <= 1 ? 'hide-tabs' : ''}`}>
                <Tabs
                    variant="pills"
                    defaultActiveKey={optionSelected == "Both"?"Electric":((isDefaultGas || !hasElectricPlans) ? Object.keys(utilityList)[1] : Object.keys(utilityList)[0])}
                    // id="plan-modal-commodity-tab"
                    // activeKey={optionSelected == "Both"?"Electric":optionSelected}
                    activeKey={Object.keys(utilityList).includes(activeKey)?activeKey:Object.keys(utilityList)[0]}
                >
                    {
                        Object.keys(utilityList).map((key, idx) => {
                             //@ts-ignore
                            state_code = (utilityList?.Electric?.length > 0 && utilityList?.Electric[0]?.markets?.state_code) || '';
                            const _plans = data ? (data[key] ? [...data[key]].sort(comparePlans) : data[key]) || [] : [];
                            sortedPlans=_plans;
                            const plans = _plans.sort(comparePlans2);
                            const findUtility = utilityList[key]?.find((item: any) => (item.utility_code == utility[key] || item.id_utility == utility[key]) );
                            const currentUtilityName = findUtility?.name || (utilityList[key]?.length > 0 ? utilityList[key][0]?.name : "");
                            const currentUtilityId = findUtility?.id_utility  || (utilityList[key]?.length > 0 ? utilityList[key][0]?.id_utility : "");
                            const findPlan = selectedPlans.filter((plan: any) => plan.commodity_name === key);
                            
                            return (
                                <Tab eventKey={key} key={idx}title={(() => {
                                    return <span onClick={()=>handleTabClick(key)} style={{color:"#979797"}}>
                                            <span style={{display:"flex", alignItems:"center"}}>
                                                {t(key)}
                                                {key == "Electric" && <img style={{paddingLeft:"4px"}} src='/images/electricityIcon.svg' />}
                                                {key == "Gas" && <FireOutlined style={{color:"#274067",fontSize:"20px"}} rev={undefined} />}
                                                { findPlan.length > 0 && <i>{findPlan.length}</i> }
                                            </span>
                                        </span>
                                })()}>
                                    <PlanDetailBlock>
                                        {
                                            loading ? <div className="d-flex justify-content-center pt-5 pb-5">
                                                <LoadingRow />
                                            </div> :
                                            <>
                                                {
                                                    utilityList[key].length === 1 &&
                                                    <PlanUtilityRow><b>{t('Service Area')}:</b>{utilityNameWrapper(currentUtilityName)}</PlanUtilityRow>
                                                }
                                                {
                                                    utilityList[key].length > 1 &&
                                                    <PlanUtilityRow>
                                                        <b>{t('Service Area')}:</b>
                                                        <Dropdown className="utility-select">
                                                            <Dropdown.Toggle variant="light">
                                                                {utilityNameWrapper(currentUtilityName)}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {
                                                                    utilityList[key].map((val: any, idx: number) => (
                                                                        <Dropdown.Item
                                                                            eventKey={idx}
                                                                            key={idx}
                                                                            onClick={() => {
                                                                                handleChangeUtility(key, val.utility_code);
                                                                            }}
                                                                            active={val.id_utility === currentUtilityId}
                                                                        >{utilityNameWrapper(val.name)}</Dropdown.Item>
                                                                    ))
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </PlanUtilityRow>
                                                }
                                                {
                                                    plans.length > 0 ?
                                                    <StyledPlanList>
                                                        {
                                                            plans.map((item: PlanProps, idx: number) => 
                                                            <PlanRow
                                                                data={item}
                                                                handleSelectProduct={(plan: any) => {selectProduct(plan, findUtility?.utility_code); if((!(!isTx && (utilityList && Object.keys(utilityList).length > 1)))){ 
                                                                    let params = window.location.href.split("?")[1]
                                                                    params ? navigate(`/serviceAddress?${params}`) : navigate(`/serviceAddress`)
                                                                    setStep(1)
                                                                }}}
                                                                isSelected={selectedPlans && selectedPlans.find((plan: any) => plan.plan_id === item.plan_id)}
                                                                isTx={isTx}
                                                                key={idx}
                                                                isAgent={isAgent}
                                                                currentUtilityName={utilityNameWrapper(currentUtilityName)}
                                                                selectedUtility={findUtility}
                                                                zipcode={zipcode}
                                                            />)
                                                        }
                                                    </StyledPlanList> :
                                                    <div className="no-data">{t('No Data Found')}</div>
                                                }
                                            </>
                                        }
                                    </PlanDetailBlock>
                                </Tab>
                            )
                        })
                    }
                </Tabs>
                <style>
                    {`
                    .nav-pills .nav-item {
                        background: #F4F4F7;
                        border: 0px;
                        font-size: 24px;
                        font-weight: 500;
                        width: auto;
                        border-radius: 0;
                        margin: 5px;
                    }
                    .nav-pills .nav-item.active {
                        color: #fff;
                        font-weight: 500;
                        border-radius: 9px;
                        background: #FFF;
                        width: auto;
                        margin: 5px;
                        border: 2px solid #00B7F1;

                    }
                    .nav-pills, .nav-pills:hover {
                        display: flex;
                        justify-content: space-between;
                        margin: 0;
                        background: #F4F4F7;
                        width: fit-content;
                        border-radius: 9px;
                        // box-shadow: 6px -6px 25px 0px rgba(159, 197, 255, 0.25), -6px 6px 25px 0px rgba(159, 197, 255, 0.25);
                    }
                    @media (max-width: 768px) {
                        .nav-pills .nav-item {
                           padding: 4px 8px;
                        }
                    }
                    `}
                </style>
            </div>
        )
    }

    useEffect(()=>{
        (async()=>{
            let _isAdmin = await isAdmin();
            setAgent(_isAdmin)
        })();
    },[])
    if(!show)return null;
    return (
        <>
                { planBlock() }
                {
                    (!isTx && (utilityList && Object.keys(utilityList).length > 1)) &&
                        <Button onClick={() => handleConfirm()} className="position-relative">
                            {t('Continue')}
                        </Button>
                }
        </>
    )
}


const PlanRow = ({ data, handleSelectProduct, isTx, isSelected,isAgent,currentUtilityName,selectedUtility,zipcode }: {
    data: PlanProps;
    handleSelectProduct: Function;
    isTx: boolean;
    isSelected: boolean;
    isAgent: boolean;
    currentUtilityName: String;
    selectedUtility: any;
    zipcode:string;
}) => {
    const { t, i18n } = useTranslation('enrollment');
    const {
        plan_name,
        contract_term,
        cancel_fee,
        cancel_fee_type,
        efl_url,
        terms_url,
        yrac,
        price2000,
        rate,
        commodity_clean_name,
        plan_desc,
        docs,
        energy_charge1,
        base_fee1,
        price500,
        price1000,
    } = data;

    const newEflUrl = data?.docs?.find((item:any) => item.description === "TX EFL FREE MONTH")?.url;
    const [ marketPrice, setMarketPrice ] = useState<any>(undefined);
    const [ OhComparisonData, setOhComparisonData ] = useState<any>(OhComparisonDataStatic);
    const [ canShow, setCanShow ] = useState(false);

    useEffect(()=>{
        (async ()=>{
            if(!zipcode) return;
            try{
                const _data1 = await fetchUSNCompareDataNew(zipcode, selectedUtility?.id_utility);
                let _tempObj = {
                    [selectedUtility?.id_utility]: _data1?.response
                };
                setOhComparisonData(_tempObj);

                if(typeof Object.values(_tempObj)[0] === "object"){
                    setCanShow(true)
                }else{
                    setCanShow(false)
                }
            }catch(err){
                setOhComparisonData(OhComparisonDataStatic);
            }
        })()
    },[selectedUtility, zipcode])

    useEffect(() => {
        if(plan_name==DUKE_CAMPAIGN && selectedUtility?.name?.toLowerCase()?.includes(DUKE_CAMPAIGN_UTILITY)){
            let utilityCode;
    
            if (selectedUtility) {
                utilityCode = selectedUtility?.id_utility;
            }
    
            if (Object.keys(OhComparisonData).indexOf(String(utilityCode)) >= 0) {
                if(OhComparisonData[utilityCode]){
                    setMarketPrice(OhComparisonData[utilityCode]);
                }
            }
            else {
                setMarketPrice(OhComparisonData['CEIL']);
            }
        }
    }, [selectedUtility, OhComparisonData])


    const displayDocs = () => {
        if (isTx) {
            return (
                <div style={{display:isAgent?"flex":"block",justifyContent:"space-between"}}>
                    {
                        efl_url ?
                        <PlanSummaryRow narrow>
                            <a href={efl_url} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="file-pdf" />
                                <span className="ms-2">EFL</span>
                            </a>
                        </PlanSummaryRow> : 
                        <PlanSummaryRow narrow>
                            <a href={newEflUrl} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="file-pdf" />
                                <span className="ms-2">EFL</span>
                            </a>
                        </PlanSummaryRow>
                    }
                    {
                        terms_url &&
                        <PlanSummaryRow narrow>
                            <a href={terms_url} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="file-pdf" />
                                <span className="ms-2">TOS</span>
                            </a>
                        </PlanSummaryRow>
                    }
                    {
                        yrac &&
                        <PlanSummaryRow narrow>
                            <a href={yrac} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="file-pdf" />
                                <span className="ms-2">YRAC</span>
                            </a>
                        </PlanSummaryRow>
                    }
                </div>
            )
        }
        if (docs) {
            const availableDocs = docs.filter((val: any) => val.display_in_plan_listing === true)
            return availableDocs.map((doc: any, idx: number) => (
                <PlanSummaryRow narrow key={idx}>
                    <a href={doc.url} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon="file-pdf" />
                        <span className="ms-2">{doc.display_name || doc.description}</span>
                    </a>
                </PlanSummaryRow>
            ))
        }
        return null;
    }

    const text = `
        A dog is a type of domesticated animal.
        Known for its loyalty and faithfulness,
        it can be found as a welcome guest in many households across the world.
        `;

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <div style={{fontSize:"15px",fontWeight:600,display:"flex",justifyContent:"space-between"}}>
                <div>{`${t('Rate')}${isTx ? ` (${t('Based on 2,000 kWh')})` : ''}`}:</div>
                <div style={{fontSize:"17px",fontWeight:700,color:"#254066"}}>{`${isTx ? price2000 : rate}¢`}</div>
                </div>,
            children: <div style={{background:"#EFF7FA"}}>
                    <div style={{fontSize:"12px",fontWeight:400,display:"flex",justifyContent:"space-between",color:"#254066"}}>
                        <div>{t('Energy Charge')}:</div>
                        <div style={{fontWeight:600}}>{`${parseFloat((Number(energy_charge1)*100)?.toFixed(2))}¢ per kWh `}</div>
                    </div>
                    <div style={{fontSize:"12px",fontWeight:400,display:"flex",justifyContent:"space-between",color:"#254066",paddingTop:"8px"}}>
                        <div>{t('Base Fee')}:</div>
                        <div style={{fontWeight:600}}> {`$${parseFloat((Number(base_fee1)*100)?.toFixed(2))} per bill month`}</div>
                    </div>
                    <div style={{fontSize:"12px",fontWeight:400,display:"flex",justifyContent:"space-between",color:"#254066",paddingTop:"8px"}}>
                        <div>{`${currentUtilityName} Charges`}:</div>
                        <div style={{fontWeight:600}}>{`$${parseFloat(Number(selectedUtility?.tdsp_FixedFee).toFixed(4))} per bill month & ${parseFloat(Number(selectedUtility?.tdsp_PerUnitOfMeasure).toFixed(4))}¢ per kWh.`}</div>
                    </div>
                </div>,
        },
      ];

    const items2: CollapseProps['items'] = [
        {
            key: '1',
            label: <div style={{fontSize:"15px",fontWeight:600,display:"flex",justifyContent:"space-between"}}>
                <div>{t('Plan Length')}:</div>
                <div style={{fontSize:"17px",fontWeight:700,color:"#254066"}}>{contract_term} {t('Months')}</div>
                </div>,
            children: <></>,
        },
      ];

    const items3: CollapseProps['items'] = [
        {
            key: '1',
            label: <div style={{fontSize:"15px",fontWeight:600,display:"flex",justifyContent:"space-between"}}>
                <div>{t('Cancellation Fee')}:</div>
                <div style={{fontSize:"17px",fontWeight:700,color:"#254066"}}>{`${cancel_fee} ${t(cancel_fee_type)}`}</div>
                </div>,
            children: <></>,
        },
      ];

    const onChange = (key: string | string[]) => {
    console.log(key);
    };

    return (isAgent && isTx)?
        <StyledAgentPlanRow>
            <div>
                <div className="d-flex align-items-center mb-3">
                    {
                        (Boolean(getEcoOrGasNewPlansName(plan_name, currentUtilityName))) ? (
                            <div className="renewable_type me-2">
                                <img src={getCommodityIconSrc(getEcoOrGasNewPlansName(plan_name, currentUtilityName))} width="32" height="32" alt="" />
                            </div>
                        ) : (
                            commodity_clean_name ?
                            <div className="renewable_type me-2">
                                <img src={getCommodityIconSrc(commodity_clean_name)} width="32" height="32" alt="" />
                            </div> :
                            (
                                plan_name.toLowerCase().includes("easy") && <div className="renewable_type me-2">
                                    <img src={getCommodityIconSrc("easy")} width="32" height="32" alt="" />
                                </div>
                            )
                        )
                    }
                    <div className="plan-name">{plan_name}</div>
                </div>
                {
                    i18n.language === 'en' &&
                    <PlanDesc desc={plan_desc} />
                }
                <div>
                    {(isAgent && Boolean(Number(price500) || Number(price1000) || Number(price2000))) && <div style={{display:"flex",justifyContent:"space-between",marginTop:"15px",
                        borderRadius: "8px",
                        padding: "8px 4px",
                        border: "2px solid #EFF7FA"
                    }}>
                        <div style={{textAlign:"center"}}>
                            <div style={{fontWeight:600,fontSize:"11px"}}>Average Monthly Use</div>
                            <div style={{fontWeight:500,fontSize:"11px"}}>Average price ¢ / kWh</div>
                        </div>
                        <div style={{textAlign:"center"}}>
                            <div style={{fontWeight:600,fontSize:"11px"}}>500 kWh</div>
                            <div style={{fontWeight:500,fontSize:"11px"}}>{`${price500}¢`}</div>
                        </div>
                        <div style={{textAlign:"center"}}>
                            <div style={{fontWeight:600,fontSize:"11px"}}>1,000 kWh</div>
                            <div style={{fontWeight:500,fontSize:"11px"}}>{`${price1000}¢`}</div>
                        </div>
                        <div style={{textAlign:"center"}}>
                            <div style={{fontWeight:600,fontSize:"11px"}}>2,000 kWh</div>
                            <div style={{fontWeight:500,fontSize:"11px"}}>{`${price2000}¢`}</div>
                        </div>
                    </div>}
                    <Collapse
                        onChange={onChange}
                        expandIconPosition={'end'}
                        items={items}
                        style={{
                            color:"#446596",
                            background:"#EFF7FA",
                            border:"none",
                            margin:"8px 0px"
                        }}
                    />
                    <Collapse
                        className="no-arrow"
                        onChange={onChange}
                        expandIconPosition={'end'}
                        activeKey={[]}
                        items={items2}
                        style={{
                            color:"#446596",
                            background:"#EFF7FA",
                            border:"none",
                            margin:"8px 0px"
                        }}
                    />
                    <Collapse
                        className="no-arrow"
                        onChange={onChange}
                        expandIconPosition={'end'}
                        activeKey={[]}
                        items={items3}
                        style={{
                            color:"#446596",
                            background:"#EFF7FA",
                            border:"none",
                            margin:"8px 0px"
                        }}
                    />
                </div>
                <div className="plan-links mt-3">
                    { displayDocs() }
                </div>
            </div>
            <Button secondary={isSelected} className="mt-3" onClick={() => {
                handleSelectProduct(data);
            }}>{(!isTx && isSelected) ? t('Remove') : t('Select Plan')}</Button>
            <style>
                {
                    `:where(.css-dev-only-do-not-override-xu9wm8).ant-collapse .ant-collapse-content>.ant-collapse-content-box  {
                        background:#EFF7FA;
                        padding: 8px 0px;
                    }
                    :where(.css-dev-only-do-not-override-xu9wm8).ant-collapse .ant-collapse-content {
                        border-top: 2px solid #25406626;
                        margin: 0px 16px;
                        border-bottom:none;
                    }
                    .no-arrow .anticon svg {
                        display: none;
                    }
                    `
                }
            </style>
        </StyledAgentPlanRow>:(
            (plan_name==DUKE_CAMPAIGN && selectedUtility?.name?.toLowerCase()?.includes(DUKE_CAMPAIGN_UTILITY)) ? 
            <StyledPlanRowContainer style={{ position: "relative", marginBottom: "1rem" }}>
                 <div
                    style={{
                        position: "absolute",
                        top: "-34px",
                        left: "40px",
                        backgroundColor: "#ACDEFF",
                        color: "#000",
                        padding: "10px 10px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    {customRound((1 - ((rate) / (marketPrice?.total_average_price))) * 100)}% SAVINGS VS DUKE ENERGY
                </div>
                <StyledPlanRow active={isSelected} recommended={plan_name==DUKE_CAMPAIGN && selectedUtility?.name?.toLowerCase()?.includes(DUKE_CAMPAIGN_UTILITY)}>
                    <div>
                        <div className="d-flex align-items-center mb-3">
                            {
                                (Boolean(getEcoOrGasNewPlansName(plan_name, currentUtilityName))) ? (
                                    <div className="renewable_type me-2">
                                        <img src={getCommodityIconSrc(getEcoOrGasNewPlansName(plan_name, currentUtilityName))} width="32" height="32" alt="" />
                                    </div>
                                ) : (
                                    commodity_clean_name ?
                                    <div className="renewable_type me-2">
                                        <img src={getCommodityIconSrc(commodity_clean_name)} width="32" height="32" alt="" />
                                    </div> :
                                    (
                                        plan_name.toLowerCase().includes("easy") && <div className="renewable_type me-2">
                                            <img src={getCommodityIconSrc("easy")} width="32" height="32" alt="" />
                                        </div>
                                    )
                                )
                            }
                            {<div className="plan-name">{plan_name}</div>}                
                        </div>
                        {
                            i18n.language === 'en' &&
                            <PlanDesc desc={plan_desc} />
                        }
                        <div>
                            <PlanSummaryRow>
                                <label>{`${t('Rate')}${isTx ? ` (${t('Based on 2,000 kWh')})` : plan_name.toLowerCase().includes('flexstart')?' (Based on 1,000 kWh)':''}`}:</label>
                                <span>
                                <b>{`${isTx ? (price2000) :(plan_name.toLowerCase().includes('flexstart')?price1000:rate)}¢`}</b>                        </span>
                            </PlanSummaryRow>
                            {plan_name.toLowerCase().includes('flexstart')?
                                <PlanSummaryRow>
                                    <label>{t('Plan Type')}:</label>
                                    <span>Variable</span>
                                </PlanSummaryRow>:
                                <PlanSummaryRow>
                                <label>{t('Plan Length')}:</label>
                                <span>{contract_term} {t('Months')}</span>
                            </PlanSummaryRow>}
                            <PlanSummaryRow>
                                <label>{t('Cancellation Fee')}:</label>
                                <span style={{textAlign:'right'}}>
                                    {
                                        plan_name.toLowerCase().includes('flexstart')?`${cancel_fee}`:`${cancel_fee} ${t(cancel_fee_type)}`
                                    }
                                </span>
                            </PlanSummaryRow>
                        </div>
                        <div className="plan-links mt-3">
                            { displayDocs() }
                        </div>
                    </div>
                    <Button secondary={isSelected} className="mt-3" onClick={() => {
                        handleSelectProduct(data);
                    }}>{(!isTx && isSelected) ? t('Remove') : t('Select Plan')}</Button>
                </StyledPlanRow>
            </StyledPlanRowContainer>
            : 
            <StyledPlanRow active={isSelected} recommended={plan_name==DUKE_CAMPAIGN  && selectedUtility?.name?.toLowerCase()?.includes(DUKE_CAMPAIGN_UTILITY)}>
            <>
                <div>
                    <div className="d-flex align-items-center mb-3">
                        {
                            (Boolean(getEcoOrGasNewPlansName(plan_name, currentUtilityName))) ? (
                                <div className="renewable_type me-2">
                                    <img src={getCommodityIconSrc(getEcoOrGasNewPlansName(plan_name, currentUtilityName))} width="32" height="32" alt="" />
                                </div>
                            ) : (
                                commodity_clean_name ?
                                <div className="renewable_type me-2">
                                    <img src={getCommodityIconSrc(commodity_clean_name)} width="32" height="32" alt="" />
                                </div> :
                                (
                                    plan_name.toLowerCase().includes("easy") && <div className="renewable_type me-2">
                                        <img src={getCommodityIconSrc("easy")} width="32" height="32" alt="" />
                                    </div>
                                )
                            )
                        }
                        {<div className="plan-name">{plan_name}</div>}                
                    </div>
                    {
                        i18n.language === 'en' &&
                        <PlanDesc desc={plan_desc} />
                    }
                    <div>
                        <PlanSummaryRow>
                            <label>{`${t('Rate')}${isTx ? ` (${t('Based on 2,000 kWh')})` : plan_name.toLowerCase().includes('flexstart')?' (Based on 1,000 kWh)':''}`}:</label>
                            <span>
                            <b>{`${isTx ? (price2000) :(plan_name.toLowerCase().includes('flexstart')?price1000:rate)}¢`}</b>                        </span>
                        </PlanSummaryRow>
                        {plan_name.toLowerCase().includes('flexstart')?
                            <PlanSummaryRow>
                                <label>{t('Plan Type')}:</label>
                                <span>Variable</span>
                            </PlanSummaryRow>:
                            <PlanSummaryRow>
                            <label>{t('Plan Length')}:</label>
                            <span>{contract_term} {t('Months')}</span>
                        </PlanSummaryRow>}
                        <PlanSummaryRow>
                            <label>{t('Cancellation Fee')}:</label>
                            <span style={{textAlign:'right'}}>
                                {
                                    plan_name.toLowerCase().includes('flexstart')?`${cancel_fee}`:`${cancel_fee} ${t(cancel_fee_type)}`
                                }
                            </span>
                        </PlanSummaryRow>
                    </div>
                    <div className="plan-links mt-3">
                        { displayDocs() }
                    </div>
                </div>
                <Button secondary={isSelected} className="mt-3" onClick={() => {
                    handleSelectProduct(data);
                }}>{(!isTx && isSelected) ? t('Remove') : t('Select Plan')}</Button>
            </>
            </StyledPlanRow>
        )
}
